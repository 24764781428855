.message-bubble-container {
  position: absolute;
  width: 110px;
  height: 80px;
}

.message-bubble-top-left {
  position: absolute;
  top: 50px;
  left: -80px;
}

.message-bubble-bottom-right {
  position: absolute;
  bottom: 0px;
  right: -80px;
}

.message-bubble-top-right {
  position: absolute;
  top: 0px;
  right: -80px;
}

.message-bubble-bottom-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.message-bubble {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.message-bubble-left {
  background-image: url('../../images/message-bubble-left.png');
}

.message-bubble-right {
  background-image: url('../../images/message-bubble-right.png');
}

.message-text {
  @apply font-medium;
  position: relative;
  max-width: 80%;
  text-align: center;
  color: #000000;
  padding: 10px;
  font-size: 14px;
}

@keyframes float-up-down {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(5px);
  }
}

@keyframes float-left-right {
  0%,
  100% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(5px);
  }
}

@keyframes float-top-left-bottom-right {
  0%,
  100% {
    transform: translateX(-10px) translateY(-10px);
  }
  50% {
    transform: translateX(0px) translateY(0px);
  }
}
