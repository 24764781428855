@import './components/layout.css';
@import './components/message-bubble.css';
@import './components/album.css';
@import './components/rolling-text.css';
@import './components/photo.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Nicky';
  src: url('../../public/fonts/nicky-nicky-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../../public/fonts/PlayfairDisplay-Regular.ttf')
    format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@layer components {
  html {
    @apply font-geist;
  }

  .gradient-bg-gray {
    @apply bg-gradient-to-b from-custom-gray-700 to-custom-gray-900;
  }

  .gradient-bg-red {
    @apply bg-gradient-to-b from-strawberry-red to-peel-orange;
  }
  .card {
    @apply rounded-3xl p-6 shadow-lg;
  }

  .app-colors-bg {
    background-image: url('../images/card-background.png');
    background-size: cover;
    background-position: center;
  }

  .header {
    @apply text-5xl font-nicky;
  }

  .alt-header {
    @apply text-4xl font-playfair-display;
  }
  .subheader {
    @apply text-2xl font-nicky max-w-[80%];
  }
  .logo {
    @apply w-16 h-auto;
  }
  .badge {
    @apply w-36 h-auto;
  }

  .prose {
    @apply text-snow-white text-left;
  }
  .prose h1,
  .prose h2,
  .prose h3,
  .prose h4,
  .prose h5,
  .prose h6 {
    @apply text-left;
  }
  .prose ul,
  .prose ol {
    @apply text-left pl-5;
  }
  .prose a {
    @apply text-blue-300 hover:text-blue-200;
  }

  .privacy-policy {
    @apply text-sm text-left text-snow-white;
  }
  .privacy-policy h4 {
    @apply text-lg font-bold mt-6 mb-2;
  }
  .privacy-policy p,
  .privacy-policy ul,
  .privacy-policy ol {
    @apply mb-4;
  }
  .privacy-policy ul,
  .privacy-policy ol {
    @apply pl-5;
  }
  .privacy-policy a {
    @apply text-blue-300 hover:text-blue-200;
  }

  .emoji-3d {
    @apply w-12 h-auto;
  }

  .photo-emoji {
    position: absolute;
    z-index: 3;
  }

  .confetti-emoji {
    bottom: 10px;
  }

  .flame-emoji {
    bottom: 0px;
    left: 30px;
  }

  .heart-eyes-emoji {
    right: 0px;
    bottom: 0px;
  }

  @keyframes float-left {
    0%,
    100% {
      transform: translateY(0px) rotate(-8deg);
    }
    50% {
      transform: translateY(10px) rotate(-8deg);
    }
  }

  @keyframes float-right {
    0%,
    100% {
      transform: translateY(0px) rotate(8deg);
    }
    50% {
      transform: translateY(10px) rotate(8deg);
    }
  }

  @keyframes float-up-down {
    0%,
    100% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(10px);
    }
  }
}
