.photos-container-sm {
  width: 320px;
  height: 180px;
  position: relative;
}

.photos-container-md {
  width: 380px;
  height: 250px;
  position: relative;
}

.photos-container-lg {
  width: 480px;
  height: 320px;
  position: relative;
}

.photo-container-sm {
  width: 131px;
  height: 162px;
}

.photo-container-md {
  width: 180px;
  height: 280px;
}

.photo-container-lg {
  width: 250px;
  height: 380px;
}

.photo {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 27px;
}

.photo-animation {
  animation: float 4s ease-in-out infinite;
}

.photo-1 {
  background-image: url('../../images/stock_1.jpg');
  transform: rotate(-8deg);
  animation-name: float-left;
  animation-delay: 0s;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.photo-2 {
  background-image: url('../../images/stock_2.jpg');
  animation-name: float-up-down;
  animation-delay: 0.4s;
  right: -30px;
  bottom: 10px;
  z-index: 1;
  width: 160px;
  height: 190px;
}

.photo-3 {
  background-image: url('../../images/stock_3.jpg');
  transform: rotate(8deg);
  animation-name: float-right;
  animation-delay: 0s;
  right: 0;
  bottom: 10px;
  z-index: 2;
}
