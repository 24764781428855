.sync-container {
  position: relative;
  align-items: center;
}

.rolling-text-container {
  height: 2em;
  overflow: hidden;
}

.rolling-text {
  display: inline-block;
}

.rolling-text span {
  display: block;
  font-size: 1.25rem; 
  font-family: 'Playfair Display', serif;
}
