.album-name {
  @apply text-base font-playfair-display text-snow-white;
  z-index: 2;
  position: absolute;
  bottom: 20px;
  left: 10px;
}
.album-bubble-container {
  @apply flex flex-col items-center justify-center;
  position: relative;
  height: auto;
  margin: 0 auto;
}

.album-container {
  width: 225px;
  height: 400px;
  position: relative;
  overflow: hidden;
  border-radius: 27px;
}

.album-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
}

.album-image.active {
  opacity: 1;
}

.image-slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
}

.image-slide.active {
  opacity: 1;
}
